import 'mdb-vue-ui-kit/css/mdb.min.css';

import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import * as mdbvue from 'mdb-vue-ui-kit'


const app = createApp(App)
for (const component in mdbvue) {
  app.component(component, mdbvue[component])
}

app.use(store).use(router).mount('#app')
