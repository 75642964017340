import axios from "axios";

export const options = {
  method: 'GET',
  url: 'https://unogs-unogs-v1.p.rapidapi.com/aaapi.cgi',
  headers: {
    'X-RapidAPI-Key': '7ffba58a44msh817eaf602f7ffe3p1a9cfdjsna5b0ebfbeea4',
    'X-RapidAPI-Host': 'unogs-unogs-v1.p.rapidapi.com'
  },
  params: {}
};

export const getAllMovies = async (options = null, array = []) => {
  if (options == null) throw Error("no options provided")
  let response = await axios.request(options)
  if (response.data.ITEMS.length === 100) {
    var page = parseInt(options.params.p)
    options.params.p = page + 1
    let response2 = await getAllMovies(options, response.data.ITEMS)
    return array.concat(response2)
  } else {
    return array.concat(response.data.ITEMS)
  }

}