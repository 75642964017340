import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import axios from 'axios'
import { options, getAllMovies } from "@/apiHelper"
import moment from "moment"

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default createStore({
  state: {
    searchMovies: null,
    clist: null,
    newRelease: {
      items: [],
      timestamp: false
    },
    search: {
      // End IMDB Rating
      eimdbrate: 10,
      // End Netflix Rating
      enfrate: 5,
      // Genre ID: get list using GET Genres
      genreid: 0,
      // Start IMDB rating
      simdbrate: 0,
      // Start Netflix Rating
      snfrate: 0,
      // Video Type: Any, Movie, Series,
      vtype: "Any",
      // Start Year
      syear: 1900,
      // Subtitle language
      subtitle: "Any",
      // Sort Results by: Relevance, Date, Rating, Title, VideoType, FilmYear, Runtime
      sortby: "Date",
      // List of COuntry IDs separated by a comma
      clist: "all",
      // Audio Type
      audio: "Any",
      // Results come in groups of 100.
      page: 1,
      // IMDB Votes: use gt[num] for greater than and lt[num] for less than e.g. gt1000 will give you titles with more than 1000 imdb votes
      imdbvotes: 0,
      // This makes subtitle and audio searches inclusive or exclusive. e.g by using 'or' you will get a match if audio is English or subtitles are English and by using 'and' you will only get a match if both audio and subtitle are English
      andor: "and",
      new: false,
      newDays: 7,
      // End Year
      eyear: 2022,
    },
  },
  getters: {
  },
  mutations: {
    update(state, data) {
      state[data[0]] = data[1]
    },
    updateSortNewRelease(state, array) {
      state.newRelease.items = array
    }
  },
  actions: {
    sortNewRelease(context, whatProperty = "unogsdate", order = "DESC") {
      if (order == "DESC") {
        order = -1
      } else {
        order = 1
      }
      console.log(context.state.newRelease);
      var newArray = context.state.newRelease.items.sort((a, b) => {
        var aDate = moment(a[whatProperty])
        var bDate = moment(b[whatProperty])

        var result = (aDate.isBefore(bDate)) ? -1 : (aDate.isAfter(bDate)) ? 1 : 0;
        return result * order
      })
      console.log(newArray)
      context.commit("updateSortNewRelease", newArray)
    },
    async searchMovies(context) {
      var newSearch = "";
      if (context.state.search.new) {
        newSearch += "new" + this.newDays;
      }

      options.params = {
        q: `get:${newSearch}-!${context.state.search.syear},${context.state.search.eyear}-!${context.state.search.snfrate},${context.state.search.enfrate}-!${context.state.search.simdbrate},${context.state.search.eimdbrate}-!${context.state.search.genreid}-!${context.state.search.vtype}-!${context.state.search.audio}-!${context.state.search.subtitle}-!gt${context.state.search.imdbvotes}-!{downloadable}`,
        t: "ns",
        cl: context.state.search.clist,
        st: "adv",
        ob: context.state.search.sortby,
        p: context.state.search.page,
        sa: context.state.search.andor,
      };
      let response = await axios.request(options)
      context.commit("update", ["searchMovies", response.data])
    },
    async updateNewRelease(context, forceReload = false) {
      let fetchMovies = forceReload;
        if (context.state.newRelease.timestamp == false ) {
          fetchMovies = true
        } else {
          if (!forceReload) {
            var timestamp = moment(context.state.newRelease.timestamp, "X")
            var now = moment()
            console.log(now.isAfter(timestamp, "day"))
            if (now.isAfter(timestamp, "day")) {
              fetchMovies = true
            }
          } else {
            fetchMovies = true
          }
        }
        if (fetchMovies) {
          options.params = { q: 'get:new14:CZ', p: '1', t: 'ns', st: 'adv' }
          let items = await getAllMovies(options)
          context.commit("update", ["newRelease", { "timestamp": moment().format("X"), "items": items }])
        }
        context.dispatch("sortNewRelease")
    },
    getClist(context) {
      return new Promise((resolve) => {
        if (context.state.clist === null) {
          const options = {
            method: 'GET',
            url: 'https://unogs-unogs-v1.p.rapidapi.com/aaapi.cgi',
            params: {t: 'lc', q: 'available'},
            headers: {
              'X-RapidAPI-Key': '7ffba58a44msh817eaf602f7ffe3p1a9cfdjsna5b0ebfbeea4',
              'X-RapidAPI-Host': 'unogs-unogs-v1.p.rapidapi.com'
            }
          };
    
          axios.request(options).then(result => {
            let items = result.data.ITEMS
            context.commit("update", ["clist", items])
            resolve(items)
          })
        } else {
          resolve(context.state.clist)
        }
        
      }) 
    }
  },
  modules: {
  },
  plugins: [vuexLocal.plugin]
})
