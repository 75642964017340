<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link :to="{name: 'NewRelease'}">Novinky</router-link>
  </nav>
  <router-view/>
</template>

<style lang="scss">
@import '~@/../mdb/scss/index.free.scss';

#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
}
body{
  background-color: #141414;
  color: white!important;
}
.form-outline .form-control ~ .form-label {
  color: white!important;;
}
.form-control{
  color: white!important;;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
