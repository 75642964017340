<template>
  <MDBContainer>
    <MDBRow>
      <MDBCol>
        <h1 style="color: #e50914">Netflix search</h1>
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol class="mb-4" sm="3">
        <div class="select-wrapper">
          <div class="form-outline">
            <select class="form-control active" v-model="search.subtitle">
              <option value="Any">Všechny</option>
              <option value="Czech">České</option>
              <option value="English">Anglické</option>
              <option value="Polish">Polské</option>
            </select>
            <span class="select-arrow"></span>
            <div class="form-notch">
              <div class="form-notch-leading" style="width: 9px"></div>
              <div class="form-notch-middle" style="width: 50px"></div>
              <div class="form-notch-trailing"></div>
            </div>
            <div class="form-label select-fake-value">Titulky</div>
          </div>
        </div>
      </MDBCol>
      <!-- Language -->
      <MDBCol class="mb-4" sm="3">
        <div class="select-wrapper">
          <div class="form-outline">
            <select class="form-control active" v-model="search.audio">
              <option value="Any">Všechny</option>
              <option value="Czech">Český</option>
              <option value="English">Anglický</option>
              <option value="Polish">Polský</option>
            </select>
            <span class="select-arrow"></span>
            <div class="form-notch">
              <div class="form-notch-leading" style="width: 9px"></div>
              <div class="form-notch-middle" style="width: 40px"></div>
              <div class="form-notch-trailing"></div>
            </div>
            <div class="form-label select-fake-value">Jazyk</div>
          </div>
        </div>
      </MDBCol>
      <!-- Movie Type -->
      <MDBCol class="mb-4" sm="2">
        <div class="select-wrapper">
          <div class="form-outline">
            <select class="form-control active" v-model="search.vtype">
              <option value="Any">Vše</option>
              <option value="Movie">Film</option>
              <option value="Series">Seriál</option>
            </select>
            <span class="select-arrow"></span>
            <div class="form-notch">
              <div class="form-notch-leading" style="width: 9px"></div>
              <div class="form-notch-middle" style="width: 45px"></div>
              <div class="form-notch-trailing"></div>
            </div>
            <div class="form-label select-fake-value">Typ</div>
          </div>
        </div>
      </MDBCol>
      <!-- START YEAR -->
      <MDBCol class="mb-4" sm="2">
        <div class="select-wrapper">
          <div class="form-outline">
            <select class="form-control active" v-model="search.syear">
              <option value="1900">1900</option>
              <option value="1950">1950</option>
              <option value="1980">1980</option>
              <option value="2000">2000</option>
              <option value="2010">2010</option>
              <option value="2015">2015</option>
              <option value="2018">2018</option>
              <option value="2019">2019</option>
              <option value="2020">2020</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
            </select>
            <span class="select-arrow"></span>
            <div class="form-notch">
              <div class="form-notch-leading" style="width: 9px"></div>
              <div class="form-notch-middle" style="width: 45px"></div>
              <div class="form-notch-trailing"></div>
            </div>
            <div class="form-label select-fake-value">Rok od</div>
          </div>
        </div>
      </MDBCol>
      <MDBCol class="mb-4" sm="2">
        <div class="select-wrapper">
          <div class="form-outline">
            <select class="form-control active" v-model="search.eyear">
              <option value="2010">2010</option>
              <option value="2015">2015</option>
              <option value="2018">2018</option>
              <option value="2019">2019</option>
              <option value="2020">2020</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
            </select>
            <span class="select-arrow"></span>
            <div class="form-notch">
              <div class="form-notch-leading" style="width: 9px"></div>
              <div class="form-notch-middle" style="width: 45px"></div>
              <div class="form-notch-trailing"></div>
            </div>
            <div class="form-label select-fake-value">Rok do</div>
          </div>
        </div>
      </MDBCol>
      <!-- COUNTRY -->
      <MDBCol v-if="clist.length > 0" class="mb-4" sm="2">
        <div class="select-wrapper">
          <div class="form-outline">
            <select class="form-control active" v-model="search.clist">
              <option value="all" selected>Všechny</option>
              <option v-for="item in clist" :value="item[0]" :key="item[0]">
                {{ item[2] }}
              </option>
            </select>
            <span class="select-arrow"></span>
            <div class="form-notch">
              <div class="form-notch-leading" style="width: 9px"></div>
              <div class="form-notch-middle" style="width: 45px"></div>
              <div class="form-notch-trailing"></div>
            </div>
            <div class="form-label select-fake-value">Země</div>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol class="mb-4">
        <MDBBtn color="primary" @click="fetchSearchMovies">Hledat</MDBBtn>
      </MDBCol>
    </MDBRow>
    <template v-if="$store.state.searchMovies">
      <MDBRow>
        <MDBCol style="text-align: left">
          <p>{{ $store.state.searchMovies.COUNT }}</p>
        </MDBCol>
      </MDBRow>
      <MDBRow v-if="$store.state.searchMovies">
        <MDBCol>
          <div id="movie-cards">
            <MDBCard
              @click="getDescription(item.netflixid)"
              class="card"
              v-for="(item, index) in $store.state.searchMovies.ITEMS"
              :key="index"
            >
              <MDBCardImg :src="item.image" top :alt="item.title" />
              <MDBCardBody style="padding: 0">
                <!-- <MDBCardTitle v-html="item.title"></MDBCardTitle> -->
                <MDBCardText>
                  <MDBBadge
                    v-if="item.type == 'movie'"
                    badge="light"
                    class="text-dark badge"
                    >Film</MDBBadge
                  >
                  <MDBBadge v-else badge="light" class="text-dark badge"
                    >Seriál</MDBBadge
                  >
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </div>
        </MDBCol>
      </MDBRow>
    </template>
  </MDBContainer>
</template>

<script>
import { options } from "@/apiHelper";
import axios from "axios";
// @ is an alias to /src
export default {
  name: "HomeView",
  data() {
    return {
      list: [],
      clist: [],
      count: 0,
    };
  },
  methods: {
    async getDescription(netflixid) {
      options.params.t = "loadvideo";
      options.params.q = netflixid;
      let response = await axios.request(options);
      console.log(response);
    },
    async fetchCountryList() {
      this.clist = await this.$store.dispatch("getClist");
    },
    async fetchSearchMovies() {
      this.$store.dispatch("searchMovies");
    },
  },
  computed: {
    search: {
      get() {
        return this.$store.state.search;
      },
      set(value) {
        this.$store.commit("update", ["search", value]);
      },
    },
  },
  created() {
    //this.fetchSearchMovies()
    this.fetchCountryList();
  },
};
</script>

<style lang="scss">
#movie-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  color: black;
  .card {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
      z-index: 5;
    }
    .badge {
      position: absolute;
      top: 5px;
      right: 5px;
    }
    //width: 20%;
    .card-img,
    .card-img-top {
      border-bottom-left-radius: calc(0.5rem - 1px);
      border-bottom-right-radius: calc(0.5rem - 1px);
    }
  }
}
input {
  color: white;
}
</style>
